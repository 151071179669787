@tailwind base;
@tailwind components;

@font-face {
  font-family: "A Jannat LT";
  src: local("A Jannat LT"),
    url(./fonts/A.Jannat.LT.Bold_6.ttf) format("truetype");
}

@font-face {
  font-family: "DimaShekari";
  src: local("DimaShekari"), url(./fonts/DimaShekari.ttf) format("truetype");
}

@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* .new-font {
      font-family: ui-serif, Georgia, Cambria, "Aldhabi", Times, serif;
    } */
    .font-Jannat {
      font-family: "A Jannat LT";
    }
    .font-DimaShekari {
      font-family: "DimaShekari";
    }

    .left-minus-2 {
      left: -2rem;
    }

    .top-80vh {
      top: 80vh;
    }
    .h-80 {
      height: 80vh;
    }

    .width-third {
      width: 30%;
    }

    .min-width-300 {
      min-width: 300px;
    }
    .width-350 {
      width: 350px;
    }
    .width-250 {
      width: 250px;
    }
    .max-width-500 {
      max-width: 500px;
    }

    .max-width-200 {
      max-width: 200px;
    }

    .max-width-20ch {
      max-width: 20ch;
    }

    .height-155 {
      height: 155px;
    }
    .max-height-700 {
      max-height: 700px;
    }

    .bg-grey {
      background-color: #d3d3d3;
    }
    .bg-blue-night {
      background: #160c3e;
    }
    .bg-orange {
      background: #d29e83;
    }
    .bg-purple {
      background: #160c3e;
    }
    .bg-light-orange {
      background: #f5dfe1;
    }

    .bg-farasha-dark-purple {
      background: #210140;
    }

    .text-farasha-dark-purple {
      color: #210140;
    }

    .bg-farasha-purple {
      background: #492b83;
    }

    .text-farasha-purple {
      color: #492b83;
    }

    .text-blue-night {
      color: #160c3e;
    }
    .text-purple {
      color: #160c3e;
    }
    .text-orange {
      color: #d29e83;
    }
    .border-orange {
      border-color: #d29e83;
    }
    .text-light-orange {
      color: #f5dfe1;
    }
    .bg-green-whatsapp {
      background: #25d366;
    }
    .text-reghda-black {
      color: #5b5b5b;
    }

    .rounded-4xl {
      border-radius: 4rem;
    }

    .border-1 {
      border-width: 1px;
    }
    .border-2 {
      border-width: 2px;
    }

    .text-xxs {
      font-size: 0.5rem;
      line-height: 0.75rem;
    }
    .text-tiny {
      font-size: 0.25rem;
      line-height: 0.5rem;
    }

    .leading-raghda {
      line-height: 10;
    }

    .tracking-raghda {
      letter-spacing: 0.5em;
    }

    .text-8xl {
      font-size: 4rem;
    }

    .flex-30 {
      flex: 1 1 30%;
    }
    .flex-20 {
      flex: 1 1 20%;
    }
    .flex-35 {
      flex: 1 1 35%;
    }
    .flex-40 {
      flex: 1 1 40%;
    }
    .flex-10 {
      flex: 1 1 10%;
    }
    .flex-45 {
      flex: 1 1 45%;
    }
    .flex-50 {
      flex: 1 1 50%;
    }

    .flex-55 {
      flex: 1 1 55%;
    }
    .leading-12 {
      line-height: 3rem;
    }

    .box-shadow-custom {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .gap-10 {
      gap: 10%;
    }
    .gap-5 {
      gap: 5%;
    }

    .gap-2 {
      gap: 2%;
    }
    .border-bottom-1 {
      border-bottom: 1px solid;
    }
    .border-bottom-grey {
      border-bottom: 1px solid #d8d8d8;
    }
    .min-w-50 {
      min-width: 50px;
    }
    .min-w-200 {
      min-width: 200px;
    }
    .min-w-600 {
      min-width: 600px;
    }
    .min-w-1000 {
      min-width: 1000px;
    }

    .max-width-300 {
      max-width: 300px;
    }

    .width-60-100 {
      width: 60%;
    }
    .width-70-100 {
      width: 70%;
    }
    .width-90-100 {
      width: 90%;
    }
    .width-80-100 {
      width: 80%;
    }

    .max-width-50-100 {
      max-width: 50%;
    }
    .min-h-200 {
      min-height: 200px;
    }
    .rtl {
      direction: rtl;
    }
    .zIndexNegative {
      z-index: -1;
    }

    .h-80 {
      height: 80vh;
    }

    .min-h-80 {
      min-height: 80vh;
    }

    .h-90 {
      height: 90vh;
    }

    .h-100 {
      height: 100vh;
    }

    .h-125 {
      height: 125vh;
    }

    .h-150 {
      height: 150vh;
    }

    .text-xxs {
      font-size: 0.5rem;
      line-height: 0.75rem;
    }

    .text-tiny {
      font-size: 0.25rem;
      line-height: 0.5rem;
    }

    .top-22-100 {
      top: 22%;
    }
  }
}
