@import url("https://fonts.googleapis.com/css2?family=Almarai&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amiri:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Changa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Markazi+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lemonada&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mada&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Scheherazade&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lateef&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Harmattan&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kufam&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mirza&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rakkas&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Katibeh&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jomhuria&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vibes&display=swap");

button:focus {
  outline: none;
}
button:active {
  outline: none;
}

.gold {
  background: linear-gradient(#cfc09f 0%, #634f2c 10%, #cfc09f 40%, #ffecb3 50%, #3a2c0f 80%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  line-height: calc(100vw / 30);
  font-size: calc(100vw / 27);
  font-weight: bold;
  margin-bottom: 6px;
  
  }
  
  .gold::before {
  
  content: attr(data-gold);
  left: 5px;
  top: 0;
  z-index: -1;
  position: absolute;
  text-shadow: 
  -1px 0 1px #c6bb9f, 
  0 1px 1px #c6bb9f, 
  5px 5px 10px rgba(0, 0, 0, 0.4), 
  -5px -5px 10px rgba(0, 0, 0, 0.4);
  
  }

.TopHomePage {
  background-image: url("/public/images/BanerMain.jpg");
  background-repeat: no-repeat;
   background-size: 100% 100%; 
   background-position: 100% 100%;
   width: 100%;
   height: 75vh;
  background-size: contain;
  background-position: center;
}
.Baner2 {
  background-image: url("/public/images/baner2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
   background-size: 100% 100%; 
   background-position: 100% 100%;
   width: 100vw;
   min-height: 75vh;
  background-size: cover;
  background-position: center;
}.Baner4 {
  background-image: url("/public/images/baner4.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
   background-size: 100% 100%; 
   background-position: 100% 100%;
   width: 100vw;
   min-height: 75vh;
  background-size: cover;
  background-position: center;
}
.Baner5 {
  background-image: url("/public/images/baner5.jpg");
  background-repeat: no-repeat;
   background-size: 100% 100%; 
   background-position: 100% 100%;
   width: 100vw;
   min-height: 75vh;
  background-size: cover;
  background-position: center;
}.Baner6 {
  background-image: url("/public/images/baner6.jpg");
  background-repeat: no-repeat;
   background-size: 100% 100%; 
   background-position: 100% 100%;
   width: 100vw;
   min-height: 75vh;
  background-size: cover;
  background-position: center;
}
.Baner9 {
  background-image: url("/public/images/baner9.jpg");
  background-repeat: no-repeat;
   background-size: 100% 100%; 
   background-position: 100% 100%;
   width: 100vw;
   min-height: 75vh;
  background-size: cover;
  background-position: center;
}
.GoldenB{
  padding: 15px;
  border-radius: 25px;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB374d 0%, #FDB9314d 8%, #9f79284d 30%, #8A6E2F4d 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF4d 0%, #FFFFAC4d 8%, #D1B4644d 25%, #5d4a1f4d 62.5%, #5d4a1f4d 100%);
}
.GoldenB2{
  padding: 15px;
  border-radius: 25px;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FDB931 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}
.GoldenB3{
  min-height: 120px;
  padding: 15px;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FDB931 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}

body {
  font-family: "A Jannat LT";
  background-image: url(/public/images/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* @media screen and (min-width: 1024px) and (max-width: 1280px) {
  html {
    font-size: 20px;
  }
}
@media screen and (min-width: 1536px) and (max-width: 1920px) {
  html {
    font-size: 22px;
  }
}

@media screen and (min-width: 1921px) {
  html {
    font-size: 32px;
  }
} */

.container {
  margin: 0 auto;
  height: auto;
}

.container-stretch {
  position: absolute;
  left: 0;
  right: 0;
}

.overview-sl {
  background-image: url("/public/images/female-african-american.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.overview {
  background-image: url("/public/images/female-african-american.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
}
.background-packs {
  background-image: url("https://image.freepik.com/free-photo/decorative-rough-background_24972-295.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.card-container {
  max-width: 72rem;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 23fr;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 7rem;
  align-items: center;
  justify-items: center;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  top: 60px;
}

/* Show the dropdown menu on hover */
.dropdown .dropdownContent {
  display: block;
}

.picfit {
  object-fit: cover;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContentBlog {
  bottom: -50px;
}

/* Show the dropdown menu on hover */
.dropdownBlog .dropdownContentBlog {
  display: block;
}

.whatsapp {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
}

@media screen and (max-width: 768px) {
  .whatsapp {
    width: 50px;
    height: 50px;
    bottom: 10px;
    right: 10px;
  }
}

.swiper-button-next {
  --swiper-theme-color: #492b83;
}
.swiper-button-prev {
  --swiper-theme-color: #492b83;
}
.swiper-pagination-bullet {
  --swiper-theme-color: #492b83;
}

/* Change tailwind after (start)*/
.FormGroup {
  padding: 0;
  border-style: none;
  background-color: #d29e83;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #d29e83;
  border-radius: 4px;
  color: #5c5c5c;
}

.FormRow {
  color: #5c5c5c;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.stripe_button {
  display: block;
  font-size: 16px;
  width: 100%;
  height: 50px;
  margin-top: 1rem;
  background-color: #5c5c5c;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #5c5c5c;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  border: none;
}
.stripe_button:active {
  background-color: #5c5c5c;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #5c5c5c;
  transform: scale(0.99);
}

.FormGroup h1,
.FormGroup h3 {
  text-align: center;
}
